<template>
  <section class="flex-column flex h-full items-stretch justify-between">
    <div class="flex h-screen w-full items-center justify-center border-l-4 border-mainYellow">
      <div v-if="errors[error.statusCode] !== undefined">
        <p class="pb-4 text-8xl">Упс, ошибка {{ error.statusCode }}</p>
        <p class="text-5xl">{{ errors[error.statusCode] }}</p>
        <div class="border-b-2 border-zinc-300"></div>
        <a
          href="/"
          class="text-3xl text-mainYellow"
          >На главную</a
        >
      </div>
      <div
        v-else
        type="is-danger"
      >
        <p class="pb-4 text-8xl">Ошибка {{ error.statusCode }}</p>
        <p class="text-4xl">{{ error.message }}</p>
        <div class="border-b-2 border-zinc-300"></div>
        <a
          href="/"
          class="text-3xl text-mainYellow"
          >На главную</a
        >
      </div>
    </div>
  </section>
</template>

<script setup>
const props = defineProps({
  error: {
    type: Object,
    default: null,
  },
});

const errors = {
  301: 'Перемещено навсегда',
  302: 'Перемещено временно',
  401: 'Не авторизован',
  403: 'Запрещено',
  404: 'Такой страницы не существует',
  500: 'Внутренняя ошибка сервера',
  502: 'Ошибочный шлюз',
  503: 'Сервис недоступен',
  504: 'Шлюз не отвечает',
};
</script>
